import {
	json,
	redirect,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { useLoaderData, useLocation } from '@remix-run/react'
import {
	getStoryblokApi,
	useStoryblokState,
	StoryblokComponent,
} from '@storyblok/react'
import { useEffect, useRef } from 'react'
import { type UTMParams } from '#app/constants/utm-types'
import { useUtmParams } from '#app/hooks/useUtmParams'
import { commitSession, getSession } from '#app/server/tracking/tracking'
import { isPreview } from '#app/utils/isPreview.tsx'
import { languageSession } from '#app/utils/localisation/lang.server'
import {
	getLanguageFromCookieOrBrowserOrFallback,
	isAcceptedLanguage,
} from '#app/utils/localisation/lokalisation-utilities'
import { trackPageView } from '#app/utils/tracking/amplitude/page-view'

type LoaderData = {
	storyData: any
	pageSource: string
	utmParams: UTMParams
}

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	const currentUrl = new URL(request.url).pathname
	const session = await getSession(request.headers.get('Cookie'))
	const previousUrl = session.get('currentUrl') || 'No previous URL'
	// Capture UTM parameters
	const referrer = request.headers.get('referer') || ''
	let utmParams: UTMParams = {}
	if (referrer) {
		try {
			const refUrl = new URL(referrer)
			utmParams = {
				utm_source: refUrl.searchParams.get('utm_source') || '',
				utm_medium: refUrl.searchParams.get('utm_medium') || '',
				utm_campaign: refUrl.searchParams.get('utm_campaign') || '',
				utm_term: refUrl.searchParams.get('utm_term') || '',
				utm_content: refUrl.searchParams.get('utm_content') || '',
			}
		} catch (error) {
			console.error('Error parsing referrer URL:', error)
		}
	}

	session.set('currentUrl', currentUrl)
	const pageSource = session.get('currentUrl') || 'No previous URL'
	session.set('pageSource', pageSource)

	let slug = params['*'] ?? 'home'
	const langSession = await languageSession.getSession(
		request.headers.get('cookie'),
	)
	let language: string = langSession.get('sessionLanguage')
	language = getLanguageFromCookieOrBrowserOrFallback(language, request)
	const locale = language

	let version = isPreview() ? 'draft' : 'published'

	let sbParams: {
		version: 'draft' | 'published'
		language: string
	} = {
		version: version as 'draft' | 'published',
		language: isAcceptedLanguage(language) ? locale : 'da',
	}

	let { data } = await getStoryblokApi()
		.get(`cdn/stories/${slug}`, sbParams)
		.catch(e => {
			console.error('Error fetching Storyblok data:', e)
			return { data: null }
		})
	if (!data && params['*'] !== 'undefined') {
		return redirect('404-not-found')
	} else if (params['*'] === 'undefined') {
		return redirect('/')
	}

	// Should replace this one with storesInformation
	let allStores = await getStoryblokApi().get('cdn/stories', {
		...sbParams,
		starts_with: 'store-opening-times/',
	})

	const storesInformation = await getStoryblokApi().get('cdn/stories', {
		...sbParams,
		starts_with: `stores-information`,
	})

	let storeOpeningTime = allStores.data.stories.find(
		(story: { slug: string }) => `stores/${story.slug}` == slug,
	)
	return json(
		{
			storyData: data?.story,
			openingTime: storeOpeningTime ? storeOpeningTime : null,
			allOpeningTimes: allStores.data.stories,
			locale,
			pageSource: previousUrl,
			storesInformation: storesInformation.data.stories,
			utmParams, // Add UTM params to the loader data
		},
		{
			headers: {
				'Set-Cookie': await commitSession(session),
			},
		},
	)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	const seoMetatags = data?.storyData?.content?.metatags
	if (seoMetatags) {
		return [
			seoMetatags.title && {
				title: seoMetatags.title,
			},
			seoMetatags.description && {
				name: 'description',
				content: seoMetatags.description,
			},
			//og
			seoMetatags.og_title && {
				property: 'og:title',
				content: seoMetatags.og_title,
			},
			seoMetatags.og_description && {
				property: 'og:description',
				content: seoMetatags.og_description,
			},
			seoMetatags.og_image && {
				property: 'og:image',
				content: seoMetatags.og_image,
			},
			//twitter
			seoMetatags.twitter_title && {
				property: 'twitter:title',
				content: seoMetatags.twitter_title,
			},
			seoMetatags.twitter_description && {
				property: 'twitter:description',
				content: seoMetatags.twitter_description,
			},
			seoMetatags.twitter_image && {
				property: 'twitter:image',
				content: seoMetatags.twitter_image,
			},
		].filter(Boolean)
	} else {
		return [
			{ title: 'Plenty &' },
			{
				name: 'description',
				content: 'Your own experience shopping in the city',
			},
		]
	}
}

export default function Page() {
	let { storyData, pageSource } = useLoaderData<LoaderData>()
	const utmParams = useUtmParams()
	const location = useLocation()
	const hasTrackedRef = useRef<string | null>(null)

	storyData = useStoryblokState(storyData)

	useEffect(() => {
		if (hasTrackedRef.current !== location.pathname) {
			trackPageView(location.pathname, pageSource, utmParams)
			hasTrackedRef.current = location.pathname
		}
	}, [location.pathname, pageSource, utmParams])

	return <StoryblokComponent blok={storyData.content} />
}
